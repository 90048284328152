<template>
  <asom-grid :sm="1" :md="1" :lg="1" class="overflow-auto w-auto">
    <asom-form-field :label="label" :state="state" :error="error" class="mr-4 w-auto">
      <table
        class="mt-2 w-auto divide-y divide-gray-200 border border-gray-200"
      >
        <caption class="hidden"></caption>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Denominations
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              10¢
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              20¢
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              50¢
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              1$
            </th>
          </tr>
        </thead>
        <tbody class="w-full divide-y divide-gray-200 border border-gray-200">
          <tr class="divide-x divide-gray-200">
            <td class="px-3 text-sm font-medium text-gray-900 text-left">
              {{ noOfTubesLabel }}
            </td>
            <td class="text-sm text-center">
              <input
                type="number"
                min="0"
                oninput="validity.valid||(value='');"
                v-if="!readonly"
                v-model.number="innerNoOf10CentTubes"
                class="border-none w-full text-center"
              />
              <span v-else>{{ noOf10CentTubes }}</span>
            </td>
            <td class="text-sm text-center">
              <input
                type="number"
                min="0"
                oninput="validity.valid||(value='');"
                v-if="!readonly"
                v-model.number="innerNoOf20CentTubes"
                class="border-none w-full text-center"
              />
              <span v-else>{{ noOf20CentTubes }}</span>
            </td>
            <td class="text-sm text-center">
              <input
                type="number"
                min="0"
                oninput="validity.valid||(value='');"
                v-if="!readonly"
                v-model.number="innerNoOf50CentTubes"
                class="border-none w-full text-center"
              />
              <span v-else>{{ noOf50CentTubes }}</span>
            </td>
            <td class="text-sm text-center">
              <input
                type="number"
                min="0"
                oninput="validity.valid||(value='');"
                v-if="!readonly"
                v-model.number="innerNoOf1DollarTubes"
                class="border-none w-full text-center"
              />
              <span v-else>{{ noOf1DollarTubes }}</span>
            </td>
          </tr>
          <tr class="divide-x divide-gray-200" v-if="showCoinsPerTube">
            <td class="px-3 text-sm font-medium text-gray-900 text-left">
              {{ coinsPerTubeLabel }}
            </td>
            <td class="text-sm text-center">
              <span>{{ coinTubeConfiguration.numberOf10CentsPerTube }}</span>
            </td>
            <td class="text-sm text-center">
              <span>{{ coinTubeConfiguration.numberOf20CentsPerTube }}</span>
            </td>
            <td class="text-sm text-center">
              <span>{{ coinTubeConfiguration.numberOf50CentsPerTube }}</span>
            </td>
            <td class="text-sm text-center">
              <span>{{ coinTubeConfiguration.numberOf1DollarsPerTube }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </asom-form-field>
    <asom-form-field v-if="showRemarks" label="Remarks" class="w-auto">
      <p class="font-semibold mt-2" v-if="readonly">
        {{ innerRemarks || "NIL" }}
      </p>
      <asom-input-textarea
        v-else
        class="mt-2"
        v-model="innerRemarks"
        rows="3"
        placeholder=""
      />
    </asom-form-field>
  </asom-grid>
</template>

<script>
import { mapGetters } from "vuex";
import inputStateMixin from "@/components/input/inputStateMixin";

export default {
  mixins: [inputStateMixin],
  props: {
    label: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showRemarks: {
      type: Boolean,
      default: false,
    },
    noOfTubesLabel: {
      type: String,
      default: "Number of coin tubes",
    },
    coinsPerTubeLabel: {
      type: String,
      default: "Number of coins per tube",
    },
    showCoinsPerTube: {
      type: Boolean,
      default: false,
    },
    noOf10CentTubes: {
      type: Number,
      default: 0,
    },
    noOf20CentTubes: {
      type: Number,
      default: 0,
    },
    noOf50CentTubes: {
      type: Number,
      default: 0,
    },
    noOf1DollarTubes: {
      type: Number,
      default: 0,
    },
    remarks: {
      type: String,
    },
    error: {
      type: String,
    },
  },
  emits: [
    "update:noOf10CentTubes",
    "update:noOf20CentTubes",
    "update:noOf50CentTubes",
    "update:noOf1DollarTubes",
    "update:remarks",
  ],
  computed: {
    ...mapGetters({
      coinTubeConfiguration:
        "cashManagement/configuration/coinTubeConfiguration",
    }),
    innerNoOf10CentTubes: {
      get() {
        return this.noOf10CentTubes;
      },
      set(newValue) {
        this.$emit("update:noOf10CentTubes", newValue);
      },
    },
    innerNoOf20CentTubes: {
      get() {
        return this.noOf20CentTubes;
      },
      set(newValue) {
        this.$emit("update:noOf20CentTubes", newValue);
      },
    },
    innerNoOf50CentTubes: {
      get() {
        return this.noOf50CentTubes;
      },
      set(newValue) {
        this.$emit("update:noOf50CentTubes", newValue);
      },
    },
    innerNoOf1DollarTubes: {
      get() {
        return this.noOf1DollarTubes;
      },
      set(newValue) {
        this.$emit("update:noOf1DollarTubes", newValue);
      },
    },
    innerRemarks: {
      get() {
        return this.remarks;
      },
      set(newValue) {
        this.$emit("update:remarks", newValue);
      },
    },
  },
};
</script>
